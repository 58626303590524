import {
  AccountData,
  AudienceList,
  Event,
  PhoneInfo,
  SavedFormQuestion,
  SearchHistory,
  TicketV2,
  Follower,
} from "@markit/common.types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  Batch,
  getPhoneNumberRef,
  getSoftUserRef,
  getUserAudienceListsSnap,
  getUserData,
  getUserFollowingsRef,
  getUserRef,
  getUserSavedFormQuestionRef,
  getUserSavedFormQuestionsSnap,
  getUsernameRef,
} from "../../utils/FirebaseUtils";
import { AppState, AppThunk } from "../store";
import {
  firebaseAuth,
  firebaseStorage,
  getCountFromServer,
  getDoc,
  ref,
  setDoc,
  signOut,
  updateDoc,
} from "../../firebase";
import { deleteObject } from "firebase/storage";
import { makeEmptyAccount } from "../../utils/makeEmptyData";
import { eventActions } from "./eventSlice";
import { conversationActions } from "./conversationSlice";
import { API } from "../../API";
import { checkForPaymentEnabled } from "../../utils/stripeAccountUtils";
import { saveMediaToFirestore } from "../../utils/photoUtils";
import isEqual from "lodash.isequal";

export enum LoginState {
  // eslint-disable-next-line no-unused-vars
  LOGGED_IN,
  // eslint-disable-next-line no-unused-vars
  LOGGED_OUT,
  // eslint-disable-next-line no-unused-vars
  FETCHING,
}

export interface AccountState {
  loggedIn: LoginState;
  appInitialized: boolean;
  isRCA: boolean;
  redirectPath: string;
  linkTrackerId: string;
  accountData: AccountData;
  currentEvent: Event | undefined;
  currentEventFormQuestions: SavedFormQuestion[];
  currentRoleTicket: TicketV2 | undefined;
  followingAccountData: AccountData[];
  followersData: Follower[];
  loadedAllFollowers: boolean;
  audienceLists: AudienceList[];
  numFollowers: number;
  numFollowing: number;
  readyForPayment: boolean;
  sidebarCondensed: boolean;
  savedQuestions: SavedFormQuestion[];
}

export const initialAccountState: AccountState = {
  loggedIn: LoginState.FETCHING,
  appInitialized: false,
  isRCA: false,
  redirectPath: "",
  linkTrackerId: "",
  accountData: makeEmptyAccount(),
  currentEvent: undefined,
  currentEventFormQuestions: [],
  currentRoleTicket: undefined,
  followingAccountData: [],
  followersData: [],
  loadedAllFollowers: false,
  audienceLists: [],
  numFollowers: 0,
  numFollowing: 0,
  readyForPayment: false,
  sidebarCondensed: false,
  savedQuestions: [],
};

export const accountSlice = createSlice({
  name: "account",
  initialState: initialAccountState,
  reducers: {
    login: (state, action: PayloadAction<AccountData>) => {
      state.loggedIn = LoginState.LOGGED_IN;
      state.accountData = action.payload;
      state.redirectPath = "";
    },
    logout: (state) => {
      state.loggedIn = LoginState.LOGGED_OUT;
      state.accountData = makeEmptyAccount();
      state.followingAccountData = [];
      state.followersData = [];
      state.loadedAllFollowers = false;
      state.audienceLists = [];
      state.appInitialized = false;
      state.redirectPath = "";
      state.savedQuestions = [];
      state.numFollowers = 0;
    },
    setAppInitialized: (state, action: PayloadAction<boolean>) => {
      state.appInitialized = action.payload;
    },
    setIsRCA: (state, action: PayloadAction<boolean>) => {
      state.isRCA = action.payload;
    },
    setRedirectPath: (state, action: PayloadAction<string>) => {
      state.redirectPath = action.payload;
    },
    setLinkTrackerId: (state, action: PayloadAction<string>) => {
      state.linkTrackerId = action.payload;
    },
    updateAccount: (state, action: PayloadAction<Partial<AccountData>>) => {
      state.accountData = { ...state.accountData, ...action.payload };
    },
    updateCancelAtEnd: (state, action: PayloadAction<boolean>) => {
      state.accountData.customer.cancelAtEnd = action.payload;
    },
    updateSubscriptionState: (state, action: PayloadAction<any[]>) => {
      state.accountData.customer.state = action.payload[0];
      state.accountData.customer.frozenDate = action.payload[1];
    },
    updateSignedAgreement: (state, action: PayloadAction<boolean>) => {
      state.accountData.signedAgreement = action.payload;
    },
    updateProfilePicURL: (state, action: PayloadAction<string>) => {
      state.accountData.profilePicURL = action.payload;
    },
    updateFullName: (state, action: PayloadAction<string>) => {
      state.accountData.fullName = action.payload;
    },
    updateUsername: (state, action: PayloadAction<string>) => {
      state.accountData.username = action.payload;
    },
    updateBio: (state, action: PayloadAction<string>) => {
      state.accountData.bio = action.payload;
    },
    updateInstagram: (state, action: PayloadAction<string>) => {
      state.accountData.instagram = action.payload;
    },
    updateLinkedin: (state, action: PayloadAction<string>) => {
      state.accountData.linkedin = action.payload;
    },
    updateTwitter: (state, action: PayloadAction<string>) => {
      state.accountData.twitter = action.payload;
    },
    updateTiktok: (state, action: PayloadAction<string>) => {
      state.accountData.tiktok = action.payload;
    },
    updateSpotify: (state, action: PayloadAction<string>) => {
      state.accountData.spotify = action.payload;
    },
    updateHideProfileEvents: (state, action: PayloadAction<boolean>) => {
      state.accountData.hideProfileEvents = action.payload;
    },
    finishInitialStartupProcess: (state) => {
      state.accountData.haveNotFinishedInitialStartup = false;
    },
    setContactCustomMessage: (state, action: PayloadAction<string>) => {
      state.accountData.contactCustomMessage = action.payload;
    },
    setCurrentEvent: (state, action: PayloadAction<Event | undefined>) => {
      state.currentEvent = action.payload;
    },
    setCurrentEventFormQuestions: (
      state,
      action: PayloadAction<SavedFormQuestion[]>
    ) => {
      state.currentEventFormQuestions = action.payload;
    },
    setCurrentRoleTicket: (
      state,
      action: PayloadAction<[TicketV2, Event] | undefined>
    ) => {
      state.currentRoleTicket = action.payload ? action.payload[0] : undefined;
      state.currentEvent = action.payload ? action.payload[1] : undefined;
    },
    updateSearchHistory: (state, action: PayloadAction<SearchHistory>) => {
      state.accountData.searchHistory = action.payload;
    },
    addToFollowingAccountData: (state, action: PayloadAction<AccountData>) => {
      if (
        !state.followingAccountData.some(
          (user) => user.uid === action.payload.uid
        )
      ) {
        state.followingAccountData = state.followingAccountData.concat(
          action.payload
        );
      } else {
        state.followingAccountData = state.followingAccountData.filter(
          (user) => user.uid !== action.payload.uid
        );
        state.followingAccountData = state.followingAccountData.concat(
          action.payload
        );
      }
    },
    addToFollowerData: (state, action: PayloadAction<Follower>) => {
      if (
        !state.followersData.some(
          (follower) => follower.uid === action.payload.uid
        )
      ) {
        state.followersData = state.followersData.concat(action.payload);
      } else {
        state.followersData = state.followersData.filter(
          (follower) => follower.uid !== action.payload.uid
        );
        state.followersData = state.followersData.concat(action.payload);
      }
    },
    removeFromFollowingAccountData: (state, action: PayloadAction<string>) => {
      if (
        state.followingAccountData.some((user) => user.uid === action.payload)
      ) {
        state.followingAccountData = state.followingAccountData.filter(
          (user) => user.uid !== action.payload
        );
      }
    },
    addMultipleFollowerAccountData: (
      state,
      action: PayloadAction<AccountData[]>
    ) => {
      for (let i = 0; i < action.payload.length; i++) {
        if (
          !state.followingAccountData.some(
            (user) => user.uid === action.payload[i].uid
          )
        ) {
          state.followingAccountData = state.followingAccountData.concat(
            action.payload[i]
          );
        } else {
          state.followingAccountData = state.followingAccountData.filter(
            (user) => user.uid !== action.payload[i].uid
          );
          state.followingAccountData = state.followingAccountData.concat(
            action.payload[i]
          );
        }
      }
    },
    addMultipleFollowerData: (state, action: PayloadAction<Follower[]>) => {
      for (let i = 0; i < action.payload.length; i++) {
        if (
          !state.followersData.some(
            (follower) => follower.uid === action.payload[i].uid
          )
        ) {
          state.followersData = state.followersData.concat(action.payload[i]);
        } else {
          state.followersData = state.followersData.filter(
            (follower) => follower.uid !== action.payload[i].uid
          );
          state.followersData = state.followersData.concat(action.payload[i]);
        }
      }
    },
    setLoadedAllFollowers: (state, action: PayloadAction<boolean>) => {
      state.loadedAllFollowers = action.payload;
    },
    setNumFollowers: (state, action: PayloadAction<number>) => {
      state.numFollowers = action.payload;
    },
    setNumFollowing: (state, action: PayloadAction<number>) => {
      state.numFollowing = action.payload;
    },
    setReadyForPayment: (state, action: PayloadAction<boolean>) => {
      state.readyForPayment = action.payload;
    },
    addAudienceList: (state, action: PayloadAction<AudienceList>) => {
      if (
        state.audienceLists.some(
          (audienceList) => audienceList.id === action.payload.id
        )
      ) {
        state.audienceLists = state.audienceLists.filter(
          (audienceList) => audienceList.id !== action.payload.id
        );
        state.audienceLists = state.audienceLists.concat(action.payload);
      } else {
        state.audienceLists = state.audienceLists.concat(action.payload);
      }
    },
    removeAudienceList: (state, action: PayloadAction<string>) => {
      state.audienceLists = state.audienceLists.filter(
        (audienceList) => audienceList.id !== action.payload
      );
    },
    setSidebarCondensed: (state, action: PayloadAction<boolean>) => {
      state.sidebarCondensed = action.payload;
    },
    setSavedFormQuestions: (
      state,
      action: PayloadAction<SavedFormQuestion[]>
    ) => {
      state.savedQuestions = action.payload;
    },
    addSavedFormQuestions: (
      state,
      action: PayloadAction<SavedFormQuestion[]>
    ) => {
      for (let i = 0; i < action.payload.length; i++) {
        if (
          !state.savedQuestions.some(
            (question) => question.id === action.payload[i].id
          )
        ) {
          state.savedQuestions = state.savedQuestions.concat(action.payload[i]);
        } else {
          state.savedQuestions = state.savedQuestions.filter(
            (question) => question.id !== action.payload[i].id
          );
          state.savedQuestions = state.savedQuestions.concat(action.payload[i]);
        }
      }
    },
    updateCurrentEventFormQuestions: (
      state,
      action: PayloadAction<SavedFormQuestion[]>
    ) => {
      for (let i = 0; i < action.payload.length; i++) {
        const index = state.currentEventFormQuestions.findIndex(
          (question) => question.id === action.payload[i].id
        );
        if (index !== -1) {
          state.currentEventFormQuestions.splice(index, 1, action.payload[i]);
        } else {
          state.currentEventFormQuestions =
            state.currentEventFormQuestions.concat(action.payload);
        }
      }
    },
    reorderCurrentEventFormQuestions: (
      state,
      action: PayloadAction<SavedFormQuestion[]>
    ) => {
      state.currentEventFormQuestions = action.payload;
    },
    deleteCurrentEventFormQuestion: (state, action: PayloadAction<string>) => {
      state.currentEventFormQuestions = state.currentEventFormQuestions.filter(
        (question) => question.id !== action.payload
      );
    },
    resetCurrentEventFormQuestions: (state) => {
      state.currentEventFormQuestions = [];
    },
    updateProfileQuestions: (state, action: PayloadAction<string[]>) => {
      state.accountData.formQuestions = action.payload;
    },
    removeProfileQuestion: (state, action: PayloadAction<string>) => {
      const index = state.savedQuestions.findIndex(
        (question) => question.id === action.payload
      );
      state.savedQuestions[index].onProfileFollow = false;
      state.accountData.formQuestions = state.accountData.formQuestions.filter(
        (questionId) => questionId !== action.payload
      );
    },
  },
});

export const accountActions = accountSlice.actions;
export const accountReducer = accountSlice.reducer;

export const logoutUser = (): AppThunk => async (dispatch) => {
  dispatch(accountActions.logout());
  dispatch(eventActions.removeAllEvents());
  dispatch(conversationActions.removeAllConversations());
  localStorage.clear();
  signOut(firebaseAuth);
};

export const updateAccountData =
  (newAccountData: AccountData): AppThunk =>
  async (dispatch, getState) => {
    const { accountData } = getState().account;
    const userRef = getUserRef(newAccountData.uid);
    setDoc(userRef, newAccountData);
    try {
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const usernameLowercase = newAccountData.username.toLowerCase();
        if (accountData.username !== usernameLowercase) {
          dispatch(
            changeUsername(
              newAccountData.uid,
              accountData.username,
              usernameLowercase
            )
          );
        }
        dispatch(accountActions.updateAccount(newAccountData));
        if (accountData.profilePicURL !== newAccountData.profilePicURL) {
          dispatch(
            changeProfilePicURL(
              newAccountData.profilePicURL,
              newAccountData.uid
            )
          );
        }
      }
    } catch (error: any) {
      alert("Error updating account data: " + error.message);
    }
  };

export const changeSignedAgreement =
  (signedAgreement: boolean, uid: string): AppThunk =>
  async (dispatch) => {
    const userRef = getUserRef(uid);
    updateDoc(userRef, { signedAgreement: signedAgreement });
    try {
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        dispatch(accountActions.updateSignedAgreement(signedAgreement));
      }
    } catch (error: any) {
      alert("Error updating signed agreement: " + error.message);
    }
  };

export const changeProfilePicURL =
  (blobUrl: string, uid: string, oldProfilePicURL?: string): AppThunk =>
  async (dispatch) => {
    const url = await saveMediaToFirestore(
      blobUrl,
      uid + "/profilePicture/" + uid + "+" + new Date().toISOString()
    );

    try {
      const userRef = getUserRef(uid);
      updateDoc(userRef, { profilePicURL: url });
      dispatch(accountActions.updateProfilePicURL(url));

      // Delete the old profile picture if it exists
      if (oldProfilePicURL) {
        const oldFileRef = ref(firebaseStorage, oldProfilePicURL);
        await deleteObject(oldFileRef);
      }
    } catch (err: any) {
      alert("Error updating profile pic URL: " + err.message);
    }
  };

/**
 * Updates the user's fullname
 */
export const changeFullName =
  (uid: string, fullName: string): AppThunk =>
  async (dispatch) => {
    const userRef = getUserRef(uid);
    updateDoc(userRef, {
      fullName: fullName,
    });
    try {
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        dispatch(accountActions.updateFullName(fullName));
      }
    } catch (e: any) {
      alert("Error updating fullname: " + e.message);
    }
  };

/**
 * Updates the user's username
 */
export const changeUsername =
  (uid: string, previousUsername: string, username: string): AppThunk =>
  async (dispatch) => {
    const batch = new Batch("Error updating username");
    const previousUsernameRef = getUsernameRef(previousUsername);
    const usernameRef = getUsernameRef(username);
    const accountRef = getUserRef(uid);

    batch.delete(previousUsernameRef);
    batch.set(usernameRef, {
      username: username,
    });
    batch.update(accountRef, { username: username });

    try {
      await batch.commit();
      dispatch(accountActions.updateUsername(username));
    } catch (e: any) {
      alert("Error updating username");
      console.error("Error updating username: " + e.message);
    }
  };

/* Updates the user's bio field */
export const changeBio =
  (uid: string, bio: string): AppThunk =>
  async (dispatch) => {
    const userRef = getUserRef(uid);
    updateDoc(userRef, {
      bio: bio,
    });
    try {
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        dispatch(accountActions.updateBio(bio));
      }
    } catch (e: any) {
      alert("Error updating bio: " + e.message);
    }
  };

/* Updates the user's instagram field */
export const changeInstagram =
  (uid: string, instagram: string): AppThunk =>
  async (dispatch) => {
    const userRef = getUserRef(uid);
    updateDoc(userRef, {
      instagram: instagram,
    });
    try {
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        dispatch(accountActions.updateInstagram(instagram));
      }
    } catch (e: any) {
      alert("Error updating instagram: " + e.message);
    }
  };

/* Updates the user's linkedin field */
export const changeLinkedin =
  (uid: string, linkedin: string): AppThunk =>
  async (dispatch) => {
    const userRef = getUserRef(uid);
    updateDoc(userRef, {
      linkedin: linkedin,
    });
    try {
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        dispatch(accountActions.updateLinkedin(linkedin));
      }
    } catch (e: any) {
      alert("Error updating linkedin: " + e.message);
    }
  };

/* Updates the user's twitter field */
export const changeTwitter =
  (uid: string, twitter: string): AppThunk =>
  async (dispatch) => {
    const userRef = getUserRef(uid);
    updateDoc(userRef, {
      twitter: twitter,
    });
    try {
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        dispatch(accountActions.updateTwitter(twitter));
      }
    } catch (e: any) {
      alert("Error updating twitter: " + e.message);
    }
  };

/* Updates the user's tiktok field */
export const changeTiktok =
  (uid: string, tiktok: string): AppThunk =>
  async (dispatch) => {
    const userRef = getUserRef(uid);
    updateDoc(userRef, {
      tiktok: tiktok,
    });
    try {
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        dispatch(accountActions.updateTiktok(tiktok));
      }
    } catch (e: any) {
      alert("Error updating tiktok: " + e.message);
    }
  };

/* Updates the user's instagram field */
export const changeSpotify =
  (uid: string, spotify: string): AppThunk =>
  async (dispatch) => {
    const userRef = getUserRef(uid);
    updateDoc(userRef, {
      spotify: spotify,
    });
    try {
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        dispatch(accountActions.updateSpotify(spotify));
      }
    } catch (e: any) {
      alert("Error updating spotify: " + e.message);
    }
  };

/* Updates the hideProfileEvents section on profile page */
export const changeHideProfileEvents =
  (uid: string, hideProfileEvents: boolean): AppThunk =>
  async (dispatch) => {
    const userRef = getUserRef(uid);
    updateDoc(userRef, {
      hideProfileEvents: hideProfileEvents,
    });
    try {
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        dispatch(accountActions.updateHideProfileEvents(hideProfileEvents));
      }
    } catch (e: any) {
      alert("Error updating hideProfileEvents: " + e.message);
    }
  };

export const changePhoneNumber =
  (phoneNumber: string, uid: string, oldPhoneNumber?: string): AppThunk =>
  async (dispatch) => {
    const batch = new Batch("Error updating phone number");
    if (oldPhoneNumber) {
      const oldPhoneNumberRef = getPhoneNumberRef(oldPhoneNumber);
      batch.delete(oldPhoneNumberRef);

      const softUserRef = getSoftUserRef(oldPhoneNumber);
      batch.delete(softUserRef);
    }

    if (phoneNumber !== "") {
      const phoneNumberRef = getPhoneNumberRef(phoneNumber);

      const phoneInfo: PhoneInfo = {
        lastFreeCreator: "",
        phoneNumber: phoneNumber,
        userId: uid,
        sentCompliance: false,
        sentComplianceFreeCreatorPhone: false,
        optOut: false,
        optOutFreeCreatorPhone: false,
        optOutTimestamp: "",
      };
      batch.set(phoneNumberRef, phoneInfo, { merge: false });
    }

    const userRef = getUserRef(uid);
    batch.update(userRef, {
      phoneNumber: phoneNumber,
    });

    try {
      await batch.commit();
      dispatch(accountActions.updateAccount({ phoneNumber: phoneNumber }));
    } catch (e: any) {
      alert("Error editing phone number.");
      console.error("Error editing phone number: " + e.message);
    }
  };

export const changeEmail =
  (email: string, uid: string): AppThunk =>
  async (dispatch) => {
    const userRef = getUserRef(uid);
    updateDoc(userRef, { email: email });
    try {
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        dispatch(accountActions.updateAccount({ email: email }));
      }
    } catch (error: any) {
      alert("Error updating email: " + error.message);
    }
  };

export const setContactCustomMessage =
  (user: AccountData, message: string, customImage: string): AppThunk =>
  async (dispatch) => {
    const userRef = getUserRef(user.uid);
    const batch = new Batch("Update welcome message");
    await batch.update(userRef, {
      contactCustomMessage: message,
    });

    // change or remove media if it exists
    if (customImage === "") {
      batch.update(userRef, {
        contactCustomMediaUrl: "",
      });
    } else if (!isEqual(user.contactCustomMediaUrl, customImage)) {
      const url = await saveMediaToFirestore(
        customImage,
        user.uid + "/textMedia/follow"
      );
      batch.update(userRef, {
        contactCustomMediaUrl: url,
      });
    }

    await batch.commit();
    dispatch(accountActions.setContactCustomMessage(message));
  };

// This is changed if the user finished entering their username and fullName
// If this this not called, the user has not finished setting up their account on the webapp sign up flow, and will be directed to this each time they log in until changed
export const finishInitialStartup =
  (uid: string): AppThunk =>
  async (dispatch) => {
    const userRef = getUserRef(uid);
    updateDoc(userRef, { haveNotFinishedInitialStartup: false });
    try {
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        dispatch(accountActions.finishInitialStartupProcess());
      }
    } catch (e: any) {
      alert("Error finishing initial startup: " + e.message);
    }
  };

export const toggleInCreatorMode =
  (uid: string, inCreatorMode: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(
      accountActions.updateAccount({
        inCreatorMode: !inCreatorMode,
      })
    );

    const userRef = getUserRef(uid);
    const batch = new Batch("switching creator mode");
    await batch.update(userRef, {
      inCreatorMode: !inCreatorMode,
    });
    try {
      await batch.commit();
    } catch (err: any) {
      console.log("Error switching creator mode: " + err.message);
    }
  };

export const setShowedWeeklyCommunityUpdates =
  (uid: string, showedWeeklyCommunityUpdates: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(
      accountActions.updateAccount({
        showedWeeklyCommunityUpdates: showedWeeklyCommunityUpdates,
      })
    );

    const userRef = getUserRef(uid);
    const batch = new Batch("switching showedWeeklyCommunityUpdates");
    await batch.update(userRef, {
      showedWeeklyCommunityUpdates: showedWeeklyCommunityUpdates,
    });
    await batch.commit();
  };

export const setShowedCategoriesEducationModal =
  (uid: string, showedCategoriesModal: boolean): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const educationModals = state.account.accountData.showedEducationModals;
    dispatch(
      accountActions.updateAccount({
        showedEducationModals: {
          ...educationModals,
          textCategoriesModal: showedCategoriesModal,
        },
      })
    );

    const userRef = getUserRef(uid);
    const batch = new Batch("switching showedEducationModal");
    await batch.update(userRef, {
      showedEducationModals: {
        ...educationModals,
        textCategoriesModal: showedCategoriesModal,
      },
    });
    await batch.commit();
  };

export const setShowImportantMessage =
  (uid: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      accountActions.updateAccount({
        showImportantMessage: false,
      })
    );

    const userRef = getUserRef(uid);
    const batch = new Batch("Error updating important message modal state");
    await batch.update(userRef, { showImportantMessage: false });
    await batch.commit();
  };

/* Delete recently searched event(s) */
export const deleteSearchHistory =
  (
    uid: string,
    type: "marks" | "users" | "places",
    searchId: string
  ): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const history = state.account.accountData.searchHistory;
    const recent = history[type].filter((s) => s !== searchId);
    const updated = { ...history, [type]: recent };

    const userRef = getUserRef(uid);
    updateDoc(userRef, { searchHistory: updated });
    try {
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        dispatch(accountActions.updateSearchHistory(updated));
      }
    } catch (error: any) {
      alert("Error deleting search history: " + error.message);
    }
  };

/* Add event to search history */
export const addSearchHistory =
  (
    uid: string,
    type: "marks" | "users" | "places",
    searchId: string
  ): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const history = state.account.accountData.searchHistory;
    const recent = [
      searchId,
      ...history[type].filter((s) => s !== searchId),
    ].slice(0, 5);
    const updated = { ...history, [type]: recent };
    const userRef = getUserRef(uid);
    updateDoc(userRef, { searchHistory: updated });
    try {
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        dispatch(accountActions.updateSearchHistory(updated));
      }
    } catch (error: any) {
      alert("Error updating search history: " + error.message);
    }
  };

export const fetchNumUserFollowing =
  (uid: string): AppThunk =>
  async (dispatch) => {
    const followingsRef = getUserFollowingsRef(uid);
    const snapshot = await getCountFromServer(followingsRef);
    const numFollowing = snapshot.data().count;
    dispatch(accountActions.setNumFollowing(numFollowing));
  };

export const fetchReadyForPayment =
  (uid: string): AppThunk =>
  async (dispatch) => {
    const userData = await getUserData(uid);
    if (userData) {
      const readyForPayment = await checkForPaymentEnabled(userData);
      dispatch(accountActions.setReadyForPayment(readyForPayment));
    } else {
      console.error("Could not fetch user data for: ", uid);
      dispatch(accountActions.setReadyForPayment(false));
    }
  };

// fetch all of a user's followers account data and follower data
export const fetchAllUserFollowersData =
  (uid: string): AppThunk =>
  async (dispatch, getState) => {
    const { loadedAllFollowers } = getState().account;
    if (!loadedAllFollowers) {
      const [accountsResponse, followersResponse] = await Promise.all([
        API.user.followerUsers({ uid }),
        API.user.followerUsersData({ uid }),
      ]);
      if (accountsResponse) {
        const usersData = accountsResponse.followingAccounts;
        const followersData = followersResponse.followerData;
        dispatch(accountActions.addMultipleFollowerAccountData(usersData));
        dispatch(accountActions.addMultipleFollowerData(followersData));
        dispatch(accountActions.setLoadedAllFollowers(true));
      }
    }
  };

export const fetchSavedFormQuestions =
  (uid: string): AppThunk =>
  async (dispatch) => {
    const savedFormQuestionsSnap = await getUserSavedFormQuestionsSnap(uid);

    const savedFormQuestions = savedFormQuestionsSnap.docs.map((doc) =>
      doc.data()
    );
    dispatch(accountActions.setSavedFormQuestions(savedFormQuestions));
  };

// Move to backend eventually within ProfileRoute for creating a normal saved question (spreadsheet columns)
export const createSingleSavedQuestion =
  (uid: string, question: SavedFormQuestion): AppThunk =>
  async (dispatch) => {
    try {
      const batch = new Batch("Error creating single saved question");
      const savedQuestionRef = getUserSavedFormQuestionRef(uid, question.id);
      batch.set(savedQuestionRef, question);
      await batch.commit();
      dispatch(accountActions.addSavedFormQuestions([question]));
    } catch (e: any) {
      console.error(e.message);
    }
  };

// Profile form questions
export const editSingleProfileQuestion =
  (uid: string, updatedFormQuestion: SavedFormQuestion): AppThunk =>
  async (dispatch) => {
    try {
      await API.profile.editSingleProfileQuestion({
        uid: uid,
        updatedFormQuestion: updatedFormQuestion,
      });
      dispatch(accountActions.addSavedFormQuestions([updatedFormQuestion]));
    } catch (e: any) {
      alert("Error editing single profile question");
      console.error("Error editing single profile question: " + e.message);
    }
  };

export const removeSingleProfileQuestion =
  (uid: string, formQuestionId: string): AppThunk =>
  async (dispatch) => {
    try {
      await API.profile.removeSingleProfileQuestion({
        uid: uid,
        formQuestionId: formQuestionId,
      });
      dispatch(accountActions.removeProfileQuestion(formQuestionId));
    } catch (e: any) {
      alert("Error removing single profile question");
      console.error("Error removing single profile question: " + e.message);
    }
  };

export const reorderProfileQuestions =
  (uid: string, savedFormQuestionIds: string[]): AppThunk =>
  async (dispatch) => {
    try {
      await API.profile.reorderProfileQuestions({
        uid: uid,
        savedFormQuestionIds: savedFormQuestionIds,
      });
      dispatch(accountActions.updateProfileQuestions(savedFormQuestionIds));
    } catch (e: any) {
      alert("Error reordering profile questions");
      console.error("Error reordering profile questions: " + e.message);
    }
  };

export const updateProfileQuestions =
  (uid: string, savedFormQuestions: SavedFormQuestion[]): AppThunk =>
  async (dispatch) => {
    try {
      await API.profile.updateProfileQuestions({
        uid: uid,
        savedFormQuestions: savedFormQuestions,
      });
      dispatch(accountActions.addSavedFormQuestions(savedFormQuestions));
      dispatch(
        accountActions.updateProfileQuestions(
          savedFormQuestions.map((question) => question.id)
        )
      );
    } catch (e: any) {
      alert("Error updating profile questions");
      console.error("Error updating profile questions: " + e.message);
    }
  };

export const fetchAudienceLists =
  (userId: string): AppThunk =>
  async (dispatch) => {
    const audienceListsSnap = await getUserAudienceListsSnap(userId);
    await Promise.all(
      audienceListsSnap.docs.map((snapshot) => {
        const audienceListData = snapshot.data();
        dispatch(accountActions.addAudienceList(audienceListData));
        return true;
      })
    );
  };

export const createAudienceList =
  (uid: string, list: AudienceList, members?: string[]): AppThunk =>
  async (dispatch) => {
    try {
      const { audienceList } = await API.profile.createAudienceList({
        uid: uid,
        list: list,
        members: members,
      });
      dispatch(accountActions.addAudienceList(audienceList));
    } catch (e: any) {
      alert("Error adding user's audience list: " + e.message);
    }
  };

export const updateAudienceList =
  (
    uid: string,
    updatedAudienceList: AudienceList,
    updatedMembers: string[]
  ): AppThunk =>
  async (dispatch) => {
    const finalList: AudienceList = {
      ...updatedAudienceList,
      numberMembers: updatedMembers.length,
    };
    try {
      const { audienceList } = await API.profile.updateAudienceList({
        uid: uid,
        list: finalList,
        members: updatedMembers,
      });
      dispatch(accountActions.addAudienceList(audienceList));
    } catch (e: any) {
      alert("Error updating user's audience list: " + e.message);
    }
  };

export const deleteAudienceList =
  (uid: string, audienceListId: string): AppThunk =>
  async (dispatch) => {
    try {
      await API.profile.deleteAudienceList({
        uid: uid,
        listId: audienceListId,
      });
      dispatch(accountActions.removeAudienceList(audienceListId));
    } catch (e: any) {
      alert("Error deleting user's audience list: " + e.message);
    }
  };

export const addSingleAudienceListMember =
  (uid: string, list: AudienceList, newMember: string): AppThunk =>
  async (dispatch) => {
    try {
      const { audienceList } = await API.profile.addSingleAudienceListMember({
        uid: uid,
        list: list,
        newMemberUid: newMember,
      });
      dispatch(accountActions.addAudienceList(audienceList));
    } catch (e: any) {
      alert("Error adding user to Audience List");
      console.error("Error adding user to Audience List", e.message);
    }
  };

export const removeSingleAudienceListMember =
  (uid: string, list: AudienceList, removedMember: string): AppThunk =>
  async (dispatch) => {
    try {
      const { audienceList } = await API.profile.removeSingleAudienceListMember(
        {
          uid: uid,
          list: list,
          newMemberUid: removedMember,
        }
      );
      dispatch(accountActions.addAudienceList(audienceList));
    } catch (e: any) {
      alert("Error removing user to Audience List");
      console.error("Error removing user to Audience List", e.message);
    }
  };

export const getAccountState = (state: AppState) => state;
