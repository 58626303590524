import { Colors } from "../../utils/colors";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import ContactSupportButton from "../Buttons/ContactSupportButton";
import ComplianceConsentTerms from "../Compliance/ComplianceConsentTerms";
import { CircularProgress } from "@mui/material";

type IntegrationConnectProps = {
  complianceChecked: boolean;
  setComplianceChecked: (complianceChecked: boolean) => void;
  loadingConnection: boolean;
};

const IntegrationConnect = (props: IntegrationConnectProps) => {
  const { complianceChecked, setComplianceChecked, loadingConnection } = props;

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    container: {
      paddingTop: 24,
      paddingBottom: 10,
      backgroundColor: Colors.WHITE1,
      width: 547,
    },
  };

  return (
    <div
      className="ColumnNormal Centering"
      style={{ gap: 40, paddingBlock: 30 }}
    >
      {loadingConnection ? (
        <div
          className="Centering ColumnNormal"
          style={{ gap: 24, paddingTop: 70 }}
        >
          <CircularProgress
            style={{ color: Colors.BLACK, alignSelf: "center" }}
            size={30}
          />
          <span className="sectionTitle">Connecting to Eventbrite...</span>
        </div>
      ) : (
        <>
          <div
            className="ColumnNormal"
            style={{ gap: 10, textAlign: "center" }}
          >
            <span style={styles.headerText}>Connect Eventbrite</span>
          </div>
          <StandardBorderedContainer
            containerStyles={{ ...styles.container, paddingInline: 14 }}
          >
            <div className="ColumnNormal">
              <span className="bodyMedium">
                Answer the following question to connect to Eventbrite
              </span>
              <ComplianceConsentTerms
                isChecked={complianceChecked}
                setIsChecked={setComplianceChecked}
                preText="You acknowledge and agree that if you collect phone numbers through Eventbrite without using Markit's auto added form questions,"
              />
            </div>
          </StandardBorderedContainer>
        </>
      )}
      <ContactSupportButton />
    </div>
  );
};

export default IntegrationConnect;
