import React, { useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { useNavigate } from "react-router-dom";
import ConfirmActionPopup, {
  BinaryConfirmActions,
} from "../../../Containers/ConfirmPopups/ConfirmActionPopup";
import { useOnMount } from "../../../../utils/useOnMount";
import { fetchNumEventbriteFollowersQuery } from "../../../../utils/userUtils";
import { API } from "../../../../API";
import ConfirmActionModal from "../../../Containers/ConfirmPopups/ConfirmActionModal";
import AlertContainer from "../../../Containers/AlertContainer";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../utils/colors";
import RectangleButton from "../../../Buttons/RectangleButton";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";
import {
  GetTime,
  hasSubscription,
  hasSubscriptionUnlockedAdvancedData,
} from "@markit/common.utils";
import { GetNumericDate } from "../../../../utils/GetTime";
import { CircularProgress } from "@mui/material";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import EventbriteIcon from "../../../IntegrationIcons/EventbriteIcon";
import { onSnapshot } from "../../../../firebase";
import { MarkitPlusModal } from "../../../Subscription/MarkitPlusModal";
import {
  checkLastEventbriteSyncDate,
  disconnectEventbrite,
} from "../../../../utils/eventbriteUtils";
import { MixpanelContext } from "../../../../context/AnalyticsService";

enum EventbriteConfirmType {
  SYNC = "sync",
  DISCONNECT = "disconnect",
  NONE = "none",
}

type EventbritePanelSectionProps = {};

export const EventbritePanelSection = (props: EventbritePanelSectionProps) => {
  const { accountData } = useSelector(getAccountState).account;
  const mixpanel = useContext(MixpanelContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [numEventbriteContacts, setNumEventbriteContacts] = useState(-1);
  const [syncAlert, setSyncAlert] = useState(false);
  const [syncConfirmAlert, setSyncConfirmAlert] =
    useState<BinaryConfirmActions>(BinaryConfirmActions.NONE);
  const [loadingSync, setLoadingSync] = useState(false);
  const [confirmModal, setConfirmModal] = useState<EventbriteConfirmType>(
    EventbriteConfirmType.NONE
  );
  const [subModalVisible, setSubModalVisible] = useState(false);

  const styles = {
    overallContainerView: {
      flex: 1,
      width: "100%",
      borderRadius: 20,
      height: 225,
    },
  };

  const eventbriteDescription =
    "Seamlessly connect all your attendees and contacts to sync with your Markit contacts and start texting.";

  useOnMount(() => {
    (async () => {
      // Get a snapshot of the total eventbrite contacts
      const eventbriteContactsQuery = await fetchNumEventbriteFollowersQuery(
        accountData.uid
      );
      const unsubscribe = onSnapshot(eventbriteContactsQuery, (snapshot) => {
        setNumEventbriteContacts(snapshot.size);
      });
      return () => unsubscribe();
    })();
  });

  const eventbriteSyncedTimestamp = useMemo(
    () =>
      accountData.eventbriteInfo.syncedOn ||
      accountData.eventbriteInfo.connectedOn,
    [
      accountData.eventbriteInfo.connectedOn,
      accountData.eventbriteInfo.syncedOn,
    ]
  );

  // So that admins can test this without being blocked by no subscription
  const hasUnlockedAdvancedDataOrAdmin = useMemo(
    () =>
      hasSubscriptionUnlockedAdvancedData(accountData) || accountData.isAdmin,
    [accountData]
  );

  const connectEventbriteOnPress = useCallback(async () => {
    if (!hasUnlockedAdvancedDataOrAdmin) {
      if (hasSubscription(accountData)) {
        navigate("/subscription", { state: { changePlan: true } });
      } else {
        setSubModalVisible(true);
      }
      return;
    }
    const response = await API.eventScraper.authorizeEventbriteUser({
      userId: accountData.uid,
    });
    if (response) {
      window.open(response.url);
    }
  }, [accountData, hasUnlockedAdvancedDataOrAdmin, navigate]);

  const syncEventbriteOnPress = useCallback(async () => {
    const disableSync = checkLastEventbriteSyncDate(
      accountData.eventbriteInfo.syncedOn,
      accountData.isAdmin
    );
    if (disableSync) {
      setSyncAlert(true);
      return;
    }
    setLoadingSync(true);
    const { success } = await API.eventScraper.connectEventbriteData({
      eventbriteInfo: accountData.eventbriteInfo,
      userId: accountData.uid,
    });
    if (!success) {
      setConfirmModal(EventbriteConfirmType.SYNC);
    } else {
      setSyncConfirmAlert(BinaryConfirmActions.AFFIRMATIVE);
    }
    setLoadingSync(false);
  }, [accountData.eventbriteInfo, accountData.isAdmin, accountData.uid]);

  const disconnectEventbriteOnPress = useCallback(async () => {
    dispatch(disconnectEventbrite(accountData, mixpanel));
  }, [accountData, dispatch, mixpanel]);

  const editConnectionOnPress = useCallback(() => {
    navigate("/integrations");
  }, [navigate]);

  return (
    <>
      {accountData.eventbriteInfo.token ? (
        <StandardBorderedContainer
          containerStyles={{
            ...styles.overallContainerView,
            paddingBlock: 14,
            backgroundColor: Colors.WHITE,
          }}
        >
          <div className="ColumnNormal" style={{ height: "100%" }}>
            <div className="AlignedRow" style={{ gap: 14, paddingInline: 14 }}>
              <EventbriteIcon size={34} padding={10} />
              <div className="ColumnNormal" style={{ gap: 7 }}>
                <div className="AlignedRow" style={{ gap: 10 }}>
                  <span className="sectionTitle">Eventbrite</span>
                  <span className="bodySubtext">Connected</span>
                </div>
                <span className="smallBodySubtext" style={{ width: "70%" }}>
                  {eventbriteDescription}
                </span>
              </div>
            </div>
            <HorizontalDivider altMargin={14} />
            <div
              className="ColumnNormal"
              style={{
                paddingInline: 14,
                flexGrow: 1,
                justifyContent: "space-between",
              }}
            >
              <div className="ColumnNormal" style={{ gap: 10 }}>
                {numEventbriteContacts !== -1 ? (
                  <span
                    className="smallBodySubtext"
                    style={{
                      color:
                        numEventbriteContacts > 0 ? Colors.GRAY1 : Colors.RED3,
                    }}
                  >
                    Total Valid Synced Contacts: {numEventbriteContacts}
                  </span>
                ) : null}
                {accountData.eventbriteInfo.autoAddPhoneQuestion ? (
                  <div className="AlignedRow" style={{ gap: 4 }}>
                    <Icon
                      icon={"ion:checkmark-circle"}
                      height={12}
                      style={{ color: Colors.GREEN2 }}
                    />
                    <span style={{ fontSize: 12 }}>
                      Auto Collecting Phone Numbers
                    </span>
                  </div>
                ) : null}
                <span className="smallBodySubtext">
                  Connected On:{" "}
                  {GetNumericDate(accountData.eventbriteInfo.connectedOn)}
                </span>
              </div>
              <div
                className="AlignedRow"
                style={{ alignContent: "flex-end", gap: 14 }}
              >
                <div className="AlignedRow" style={{ gap: 10 }}>
                  <RectangleButton
                    buttonLabel={
                      <span style={{ fontSize: 14, fontWeight: 400 }}>
                        Disconnect
                      </span>
                    }
                    onPress={() =>
                      setConfirmModal(EventbriteConfirmType.DISCONNECT)
                    }
                    altColor={Colors.WHITE1}
                    altTextColor={Colors.RED3}
                    altPaddingHorz={14}
                    altPaddingVert={10}
                    altBorderRadius={8}
                    width={106}
                    containerStyles={{ border: "0.5px solid #EDEDED" }}
                  />
                  <div>
                    <RectangleButton
                      buttonLabel={
                        <span style={{ fontSize: 14, fontWeight: 400 }}>
                          Edit Connection
                        </span>
                      }
                      onPress={editConnectionOnPress}
                      altColor={Colors.WHITE1}
                      altTextColor={Colors.BLACK}
                      altPaddingHorz={14}
                      altPaddingVert={10}
                      altBorderRadius={8}
                      containerStyles={{ border: "0.5px solid #EDEDED" }}
                    />
                  </div>
                  {accountData.eventbriteInfo.syncedOn ? (
                    <div
                      className="AlignedRowSelect"
                      style={{ paddingInline: 9, paddingBlock: 10, gap: 10 }}
                      onClick={syncEventbriteOnPress}
                    >
                      {loadingSync ? (
                        <CircularProgress
                          style={{
                            color: Colors.BLACK,
                            alignSelf: "center",
                            paddingInline: 2,
                          }}
                          size={20}
                        />
                      ) : (
                        <Icon icon="ion:sync-sharp" height={24} />
                      )}
                      <div className="ColumnNormalSelect">
                        <span style={{ fontSize: 12, fontWeight: 500 }}>
                          Refresh Data
                        </span>
                        <span style={{ fontSize: 11, color: Colors.GRAY1 }}>
                          Last Synced:{" "}
                          {GetNumericDate(eventbriteSyncedTimestamp)}{" "}
                          {GetTime(new Date(eventbriteSyncedTimestamp))}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </StandardBorderedContainer>
      ) : (
        <StandardBorderedContainer
          containerStyles={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: Colors.WHITE,
            gap: 24,
            paddingInline: 24,
            paddingBlock: 14,
            borderRadius: 20,
          }}
        >
          <div className="ColumnNormal" style={{ gap: 24 }}>
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <span style={{ fontSize: 20, fontWeight: 500 }}>
                Eventbrite Integration{accountData.isAdmin ? " (Admin)" : ""}
              </span>
              <span className="bodySubtext">{eventbriteDescription}</span>
              {hasUnlockedAdvancedDataOrAdmin ? (
                <span className="bodyMedium">
                  Before pressing connect, make sure you{" "}
                  <span
                    onClick={connectEventbriteOnPress}
                    style={{
                      cursor: "pointer",
                      color: Colors.BLUE5,
                      textDecorationLine: "underline",
                    }}
                  >
                    login
                  </span>{" "}
                  to your Eventbrite account first.
                </span>
              ) : hasSubscription(accountData) ? (
                <span className="bodyMedium">
                  Available with Professional or Business
                </span>
              ) : (
                <span className="bodyMedium">
                  Available with Markit
                  <span style={{ color: Colors.BLUE5 }}>+</span>
                </span>
              )}
            </div>
            <RectangleButton
              buttonLabel={
                hasUnlockedAdvancedDataOrAdmin
                  ? "Connect"
                  : hasSubscription(accountData)
                  ? "Upgrade"
                  : "Connect with Markit+"
              }
              onPress={connectEventbriteOnPress}
              altColor={Colors.BLACK}
              altTextColor={Colors.WHITE}
              altPaddingHorz={24}
              altPaddingVert={14}
              width={hasSubscription(accountData) ? 106 : 200}
            />
          </div>
          <div
            className="Centering"
            style={{
              borderRadius: 20,
              padding: 24,
              backgroundColor: Colors.ORANGE_EB,
            }}
          >
            <Icon
              icon="simple-icons:eventbrite"
              height={84}
              color={Colors.WHITE}
            />
          </div>
        </StandardBorderedContainer>
      )}
      <ConfirmActionModal
        heading={
          confirmModal === EventbriteConfirmType.SYNC
            ? "Unable to re-sync with Eventbrite at this time."
            : "Are you sure you want to disconnect your Eventbrite integration with Markit?"
        }
        subtext={
          confirmModal === EventbriteConfirmType.SYNC
            ? "We weren't able to sync your latest Eventbrite data. Please try again later or contact support."
            : "This cannot be undone."
        }
        confirmButtonText={
          confirmModal === EventbriteConfirmType.SYNC
            ? "Try Again"
            : "Disconnect"
        }
        icon={
          confirmModal === EventbriteConfirmType.SYNC ? (
            <Icon icon="fa-solid:plug" height={35} />
          ) : undefined
        }
        hideModal={confirmModal === EventbriteConfirmType.NONE}
        setIsVisible={() => setConfirmModal(EventbriteConfirmType.NONE)}
        confirmOnPress={
          confirmModal === EventbriteConfirmType.SYNC
            ? syncEventbriteOnPress
            : disconnectEventbriteOnPress
        }
      />
      {syncConfirmAlert !== BinaryConfirmActions.NONE ? (
        <ConfirmActionPopup
          title={"Synced!"}
          negativeTitle={"Failed Sync"}
          isNegative={syncConfirmAlert === BinaryConfirmActions.NEGATIVE}
          onDisappear={() => setSyncConfirmAlert(BinaryConfirmActions.NONE)}
        />
      ) : null}
      <AlertContainer
        headerComp={
          "You have last synced your Eventbrite data within the last hour."
        }
        subHeaderComp={
          "Please wait until an hour later than your latest sync to try again."
        }
        closeModal={() => setSyncAlert(false)}
        hideModal={!syncAlert}
      />
      {subModalVisible ? (
        <MarkitPlusModal closeModal={() => setSubModalVisible(false)} />
      ) : null}
    </>
  );
};
